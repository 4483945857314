import request from '@/utils/request'

export function getBanner() {
  return request({
    url: '/home/banner',
    method: 'get',
  })
}
export function getProduct(query) {
  return request({
    url: '/home/product',
    method: 'get',
    params:query,
  })
}
export function getNews(query) {
  return request({
    url: '/home/news',
    method: 'get',
    params:query,
  })
}




