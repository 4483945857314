<template class="home-components">
  <div>
    <el-carousel :height="bannerHeight + 'px'">
      <el-carousel-item v-for="item in banners" :key="item.id">
        <a :href="item.link">
          <img
            class="bannerImg"
            :src="item.pic"
            width="100%"
            height="100%"
            :alt="item.title"
            :title="item.title"
          />
        </a>
      </el-carousel-item>
    </el-carousel>
    <div class="wrap">
      <div class="card card-a">
        <div class="title">产品介绍</div>
        <div class="body">
          <el-row :gutter="20">
            <el-col
              style="height: auto"
              :span="14"
              v-for="(item, index) in products.slice(0, 1)"
              :key="index"
            >
              <router-link :to="'/product/' + item.id">
                <div style="width: 709px;height: 709px;overflow: hidden">
                  <h3>{{ item.title }}</h3>
                  <img
                    class="index-product-img"
                    :src="item.cover"
                    width="100%"
                    height="100%"
                    alt=""
                    :title="item.title"
                  />
                </div>
              </router-link>
            </el-col>
            <el-col :span="10">
              <el-row>
                <el-col
                  style="margin-bottom: 21px"
                  :span="24"
                  v-for="(item, index) in products.slice(1, 5)"
                  :key="index"
                >
                  <router-link :to="'/product/' + item.id">
                    <div style="width: 501px;height: 344px;overflow: hidden">
                      <h3>{{ item.title }}</h3>
                      <img
                        class="index-product-img"
                        :src="item.cover"
                        width="100%"
                        height="100%"
                        alt=""
                        :title="item.title"
                      />
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-col style="text-align: center;margin-top: 25px;">
              <router-link to="/product">
                <el-button class="index-more-btn" type="primary" round plain>了解更多</el-button>
              </router-link>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="card card-b">
        <div class="title">新闻资讯</div>
        <div class="body">
          <el-row v-for="(item, index) in news" :key="index">
            <el-col :span="14" v-if="index % 2 === 0">
              <!-- <router-link :to="{name:'newsli',query:{id:''+item.id}}"> -->
              <div style="width: 718px;height: 400px;overflow: hidden;margin-bottom: 40px">
                <img
                  class="index-product-img"
                  :src="item.cover"
                  width="100%"
                  height="auto"
                  alt=""
                  :title="item.title"
                />
              </div>
              <!-- </router-link> -->
            </el-col>
            <el-col :span="10" v-if="index % 2 === 0">
              <el-row>
                <el-col :span="24">
                  <!-- <router-link :to="{name:'newsli',query:{id:''+item.id}}"> -->
                  <div class="index-news-body">
                    <div class="news-title">{{ item.title }}</div>
                    <p class="news-desc">{{ item.content }}</p>
                  </div>
                  <!-- </router-link> -->
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="10" v-if="index % 2 !== 0">
              <el-row>
                <el-col :span="24">
                  <!-- <router-link :to="{name:'newsli',query:{id:''+item.id}}"> -->
                  <div class="index-news-body">
                    <div class="news-title">{{ item.title }}</div>
                    <p class="news-desc">{{ item.content }}</p>
                  </div>
                  <!-- </router-link> -->
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="14" v-if="index % 2 !== 0">
              <!-- <router-link :to="{name:'newsli',query:{id:''+item.id}}"> -->
              <div style="width: 718px;height: 400px;overflow: hidden">
                <img
                  class="index-product-img"
                  :src="item.cover"
                  width="100%"
                  height="100%"
                  alt=""
                  :title="item.title"
                />
              </div>
              <!-- </router-link> -->
            </el-col>
          </el-row>
          <!-- <el-row>
                        <el-col style="text-align: center;margin-top: 56px;">
                            <router-link to="/about/news">
                                <el-button class="index-more-btn" type="primary" round plain>了解更多</el-button>
                            </router-link>
                        </el-col>
                    </el-row> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as home from '@/api/home'
import bg1 from '@/assets/bg/1.jpg'
import bg2 from '@/assets/bg/2.jpg'
import bg3 from '@/assets/bg/3.jpg'
export default {
  name: 'home',
  data: function() {
    return {
      banners: [
        {
          title: 'Qlhso Dig Crml Nbcvlt Vpsefc Huqal Fltzex Jepttjqvl Pwd',
          pic: bg1
        },
        {
          title: 'Qtjkp Xlf Ixwll Tffciss Aegclfr Iixw Ifbpkxk',
          pic: bg2
        },
        {
          title: 'Lpln Pzheumtb Xbrjrbvi Vpciuuv Chuwnnhfw Ctjepvcwu Pmiyncw',
          pic: bg3
        }
      ],

      products: [],
      news: [],
      bannerHeight: '',
      screenWidth: ''
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    const that = this
    that.screenWidth = document.body.offsetWidth
    that.setSize()

    window.addEventListener(
      'resize',
      function() {
        that.screenWidth = document.body.offsetWidth

        that.setSize()
      },
      false
    )
  },
  methods: {
    setSize: function() {
      if (this.screenWidth >= 1230) {
        this.bannerHeight = (670 / 1920) * this.screenWidth
      } else {
        this.bannerHeight = 429
      }
    },
    /**
     * @method getData
     * @param {type} argName - description
     * @return {type} argName - description
     * @description: 获取页面数据
     */
    getData() {
      // this.banners=banners.data.data;
      // this.products=products.data;
      // this.news=news.data;
      // console.log(this.products)
      // home.getBanner().then(res => {
      //   this.banners = res.data.data
      // })
      home.getProduct({ num: 3 }).then(res => {
        this.products = res.data
      })
      home.getNews({ num: 2 }).then(res => {
        this.news = res.data
      })
    }
  },
  computed: {
    productsListA: function() {
      return this.products.slice(1, 4)
    },
    productsListB: function() {
      return this.products.slice(4, 6)
    }
  }
}
</script>

<style scoped lang="less">
.bannerImg {
  width: 100%;
  height: inherit;
  min-height: 429px;
  /*min-height: 620px;*/
  min-width: 1230px;
  /*min-width: 1920px;*/
}
.wrap {
  width: 1230px;
  margin: 0 auto;
  padding-bottom: 100px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.card {
  margin-top: 120px;
  .title {
    border-left: 4px solid #0084ca;
    font-size: 36px;
    padding-left: 16px;
    margin-bottom: 56px;
    line-height: 1;
    color: #333333;
    font-weight: bold;
    text-align: left;
  }
  .index-more-btn {
    width: 192px;
    height: 54px;
    border-radius: 27px;
    border: 2px solid #0084ca;
    background: #ffffff;
    color: #0084ca;
    font-size: 20px;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
  }
  .index-more-btn:hover {
    background: #0084ca;
    color: #ffffff;
  }
  &.card-a {
    .el-col {
      position: relative;
      h3 {
        position: absolute;
        bottom: 28px;
        left: 30px;
        font-size: 30px;
        font-weight: bold;
        color: white;
        z-index: 2;
      }
    }
    .index-product-img {
      -webkit-transition: all 0.6s;
      -moz-transition: all 0.6s;
      -o-transition: all 0.6s;
      transition: all 0.6s;
    }
    .index-product-img:hover {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
  &.card-b {
    .el-col {
      position: relative;
      .a-wrap {
        display: inline-block;
        width: 100%;
        background-color: white;
        height: 592px;
      }
      img {
        /*height: 340px;*/
      }
      .index-news-body {
        width: 520px;
        height: 400px;
        overflow: hidden;
        padding-left: 39px;
        padding-right: 40px;
        background: #f5f5f5;
        -webkit-transition: all 0.6s;
        -moz-transition: all 0.6s;
        -o-transition: all 0.6s;
        transition: all 0.6s;
        .news-title {
          width: 441px;
          height: 68px;
          font-size: 22px;
          font-weight: bold;
          color: #333333;
          line-height: 34px;
          margin-top: 56px;
          margin-bottom: 24px;
        }
        .news-desc {
          width: 441px;
          height: 204px;
          font-size: 18px;
          color: #666666;
          line-height: 34px;
        }
      }
      .index-news-body:hover {
        background: #ffffff;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
      }
      .inner-card {
        position: absolute;
        top: 275px;
        width: 547px;
        height: 270px;
        left: 30px;
        background-color: white;
        padding: 28px 30px 0;
        .wrap-a {
          border-bottom: 2px solid #0084ca;
          padding-bottom: 30px;
        }

        .title-b {
          height: 60px;
          font-size: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p {
          font-size: 18px;
          color: #666;
          line-height: 1.5;
          margin-top: 20px;
          margin-bottom: 40px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          height: 84px;
        }
        a {
          font-size: 18px;
          color: #0084ca;
          padding-bottom: 30px;
        }
      }
    }
  }
}
</style>
